import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo, ProductJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbyImage } from 'gatsby-plugin-image'
import Flag from 'react-flagkit'
import Hero from '../components/hero'
import Cta from '../components/common/cta'
import Comments from '../components/common/comments'
import Rating from '../components/rating'
import RichTextRenderer from '../components/richTextRenderer'
import Share from '../components/share'
import RelatedArticles from '../components/relatedArticles'
import Tags from '../components/tags'
import RelatedWines from '../components/relatedWines'
import Banner from '../components/common/banner'
import * as wineStyling from './wine.module.scss'

export default function QuizPage({ data, pageContext, location }) {
    const { contentfulWine } = data

    const pageURL = `${data.site.siteMetadata.siteUrl}${location.pathname}`

    const convertedRating = Math.round(contentfulWine.rating / 2)

    const tags = [contentfulWine.wineType]

    const review = {
        author: {
            '@type': 'Person',
            name: 'Christian Ragnarsson',
        },
        datePublished: contentfulWine.createdAt,
        reviewBody: contentfulWine.comment,
        name: 'Vinologik betygsätter ' + contentfulWine.title,
        reviewRating: {
            bestRating: '5',
            ratingValue: convertedRating.toString(),
            worstRating: '0',
        },
    }

    const reviewArray = convertedRating !== 0 ? [review] : []

    return (
        <>
            <GatsbySeo
                title={contentfulWine.title}
                description={contentfulWine.plainDescription}
                openGraph={{
                    title: contentfulWine.title,
                    description: contentfulWine.subtitle,
                }}
            />
            <ProductJsonLd
                productName={contentfulWine.title}
                images={[contentfulWine.image.url]}
                description={contentfulWine.plainDescription}
                brand={contentfulWine.producer}
                reviews={reviewArray}
                offers={{
                    price: contentfulWine.price,
                    priceCurrency: 'SEK',
                    url: contentfulWine.systembolagetUrl,
                    seller: {
                        name: 'Systembolaget',
                    },
                    availability: contentfulWine.isStandardRange
                        ? 'InStock'
                        : 'LimitedAvailability',
                }}
            />
            <Hero
                heading={contentfulWine.title}
                body={contentfulWine.plainDescription}
            />
            <div className={wineStyling.wineWrapper}>
                {contentfulWine.image && (
                    <div className={wineStyling.imageWrapper}>
                        {contentfulWine.highScore && (
                            <Banner
                                text={'Högt betyg!'}
                                large={true}
                                farLeft={true}
                                awardIcon={true}
                            />
                        )}
                        {contentfulWine.highQpr && (
                            <Banner
                                text={'Prisvärd!'}
                                large={true}
                                farLeft={true}
                            />
                        )}
                        <GatsbyImage
                            className={wineStyling.image}
                            title={contentfulWine.title}
                            alt={contentfulWine.title}
                            image={contentfulWine.image.gatsbyImageData}
                            imgStyle={{ objectFit: 'contain' }}
                        />
                    </div>
                )}
                <div className={wineStyling.textWrapper}>
                    <h2 className={wineStyling.headline}>
                        {contentfulWine.producer}
                    </h2>
                    <h2 className="regular">
                        {contentfulWine.name}, {contentfulWine.vintage}
                    </h2>
                    <h4 className={wineStyling.subheading}>
                        <Flag
                            className={wineStyling.flag}
                            country={contentfulWine.countryCode}
                        />{' '}
                        <span>
                            <strong>{contentfulWine.country}</strong>
                            {contentfulWine.region
                                ? `, ${contentfulWine.region}`
                                : ''}
                            {contentfulWine.subregion
                                ? `, ${contentfulWine.subregion}`
                                : ''}
                        </span>
                    </h4>
                    <div className={wineStyling.tagsWrapper}>
                        <Tags tags={tags} />
                    </div>
                    {contentfulWine.rating ? (
                        <Rating rating={contentfulWine.rating} large={true} />
                    ) : (
                        <p>Vinet är ännu inte betygsatt av Vinologik.</p>
                    )}
                    {contentfulWine.comment && (
                        <p className={wineStyling.comment}>
                            {contentfulWine.comment}
                        </p>
                    )}
                    <div className={wineStyling.shareWrapper}>
                        <Share url={pageURL} size={32} contentType="vinet" />
                    </div>
                    <div className={wineStyling.factsWrapper}>
                        <h3>Fakta</h3>
                        <div className={wineStyling.factsWrapperInner}>
                            {contentfulWine.grapes && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        {contentfulWine.grapes.length > 1
                                            ? 'DRUVOR'
                                            : 'DRUVA'}
                                    </span>
                                    <h4>{contentfulWine.grapes.join(', ')}</h4>
                                </div>
                            )}
                            {contentfulWine.grapes && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        PRIS
                                    </span>

                                    <h4>{contentfulWine.price} kr</h4>
                                </div>
                            )}
                            {contentfulWine.packagingAndVolume && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        FÖRPACKNING & VOLYM
                                    </span>

                                    <h4>{contentfulWine.packagingAndVolume}</h4>
                                </div>
                            )}
                            {contentfulWine.pricePerLiter && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        PRIS PER LITER
                                    </span>

                                    <h4>{contentfulWine.pricePerLiter}</h4>
                                </div>
                            )}
                            {contentfulWine.alcoholPercentage && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        ALKOHOLHALT
                                    </span>

                                    <h4>{contentfulWine.alcoholPercentage}%</h4>
                                </div>
                            )}
                            {contentfulWine.sugarContent && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        SOCKERINNEHÅLL
                                    </span>

                                    <h4>{contentfulWine.sugarContent}</h4>
                                </div>
                            )}
                            <div>
                                <span
                                    className={`${wineStyling.factHeadline} fineprint bold`}
                                >
                                    SYSTEMBOLAGETS FASTA SORTIMENT
                                </span>

                                <h4>
                                    {contentfulWine.isStandardRange
                                        ? 'Ja'
                                        : 'Nej'}
                                </h4>
                            </div>
                            {contentfulWine.servingTemperatur && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        SERVERINGSTEMPERATUR
                                    </span>

                                    <h4>{contentfulWine.servingTemperatur}</h4>
                                </div>
                            )}
                            {contentfulWine.articleNumber && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        ARTIKELNUMMER HOS SYSTEMBOLAGET
                                    </span>

                                    <h4>{contentfulWine.articleNumber}</h4>
                                </div>
                            )}
                            {contentfulWine.importer && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        IMPORTÖR
                                    </span>

                                    <h4>{contentfulWine.importer}</h4>
                                </div>
                            )}
                            {contentfulWine.wineType && (
                                <div>
                                    <span
                                        className={`${wineStyling.factHeadline} fineprint bold`}
                                    >
                                        VINTYP
                                    </span>

                                    <h4>{contentfulWine.wineType}</h4>
                                </div>
                            )}
                        </div>
                    </div>
                    <p className="fineprint">
                        Uppdaterat {contentfulWine.updatedAt}
                    </p>
                    {contentfulWine.systembolagetUrl && (
                        <div>
                            <Cta
                                text={
                                    contentfulWine.ctaText
                                        ? contentfulWine.ctaText
                                        : 'Till Systembolaget.se'
                                }
                                isLink={true}
                                targetBlank={true}
                                to={contentfulWine.systembolagetUrl}
                            />
                        </div>
                    )}
                </div>
            </div>
            {contentfulWine.body && (
                <div className={wineStyling.bodyWrapper}>
                    <h3>Beskrivning</h3>
                    <RichTextRenderer richText={contentfulWine.body} />
                </div>
            )}
            <Comments
                contentfulPageId={contentfulWine.contentful_id}
                headline="Vad tycker du? Lämna gärna en kommentar."
            />
            <RelatedWines relatedWines={contentfulWine.relatedWines} />
            {contentfulWine.articles ? (
                <RelatedArticles
                    headline={'Relaterade artiklar'}
                    prioritizeTheseArticles={contentfulWine.articles.map(
                        (a) => a.id
                    )}
                />
            ) : (
                <RelatedArticles headline={'Relaterade artiklar'} />
            )}
        </>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        contentfulWine(title: { eq: $title }) {
            ...WineFields
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`

import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import classNames from 'classnames'
import Banner from '../components/common/banner'
import * as relatedWinesStyling from './relatedWines.module.scss'

export const query = graphql`
    fragment RelatedWinesFields on ContentfulWine {
        id
        title
        producer
        name
        vintage
        slug
        contentful_id
        country
        region
        packagingAndVolume
        grapes
        price
        highQpr
        highScore
        image {
            gatsbyImageData(height: 225, quality: 95, placeholder: BLURRED)
        }
    }
`

const RelatedWines = ({ relatedWines }) => {
    SwiperCore.use([Navigation])

    const relatedWineFineprintClasses = classNames({
        [relatedWinesStyling.fineprint]: true,
        fineprint: true,
    })

    return relatedWines.length ? (
        <div className={relatedWinesStyling.relatedWinesWrapper}>
            <h3 className={relatedWinesStyling.headline}>Liknande viner</h3>
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 2.5,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 4,
                    },
                    1100: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                    },
                }}
            >
                {relatedWines.map((wine) => (
                    <SwiperSlide
                        key={wine.contentful_id}
                        className={relatedWinesStyling.slide}
                    >
                        <Link
                            className={relatedWinesStyling.relatedWine}
                            key={wine.id}
                            to={wine.slug}
                        >
                            <div className={relatedWinesStyling.imageWrapper}>
                                {wine.highScore && (
                                    <Banner text={'Högt betyg!'} mini={true} />
                                )}
                                {wine.highQpr && (
                                    <Banner text={'Prisvärd!'} mini={true} />
                                )}
                                <GatsbyImage
                                    className={relatedWinesStyling.image}
                                    image={wine.image.gatsbyImageData}
                                    alt={wine.title}
                                />
                            </div>

                            <h4 className={relatedWinesStyling.title}>
                                {wine.producer} <br />
                            </h4>
                            <h5>
                                {wine.name} {wine.vintage}
                            </h5>
                            <h5>{wine.price} kr</h5>
                            <h5 className={relatedWineFineprintClasses}>
                                {wine.packagingAndVolume}
                            </h5>
                            <h5 className={relatedWineFineprintClasses}>
                                {wine.country} - {wine.region}
                            </h5>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    ) : null
}

RelatedWines.propTypes = {
    relatedWines: PropTypes.array,
}

export default RelatedWines

// extracted by mini-css-extract-plugin
export var banner = "wine-module--banner--c080e";
export var bodyWrapper = "wine-module--bodyWrapper--093d5";
export var comment = "wine-module--comment--331ee";
export var factHeadline = "wine-module--factHeadline--63f21";
export var factsWrapper = "wine-module--factsWrapper--cb508";
export var factsWrapperInner = "wine-module--factsWrapperInner--a9d9c";
export var flag = "wine-module--flag--665f0";
export var headline = "wine-module--headline--7ab7f";
export var image = "wine-module--image--0bc00";
export var imageWrapper = "wine-module--imageWrapper--bac0b";
export var shareWrapper = "wine-module--shareWrapper--a4c49";
export var subheading = "wine-module--subheading--3a2bd";
export var tagsWrapper = "wine-module--tagsWrapper--c439f";
export var textWrapper = "wine-module--textWrapper--84d53";
export var wineWrapper = "wine-module--wineWrapper--20043";